import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import partnersImage from '../../static/images/partners.svg'

const PartnersPage = () => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            partnerTypeformUrl
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Partners" />
      <div className={'page-header partners'}>
        <h1>Partner With Us</h1>
        <p>
          We provide exclusive benefits and access to latest features
          <br /> to all our partners and their clients.
        </p>
        <a
          href={siteMetadata.partnerTypeformUrl}
          target={'_blank'}
          className={'signup-link'}
        >
          Sign Me Up
        </a>
        <img alt={'Partners'} src={partnersImage} />
      </div>
      <div className={'call-to-action'}>
        <div className={'container'}>
          <div className={'call-to-action__content'}>
            <h2>Why partner with us?</h2>
            <ul className={'why-partner-list'}>
              <li>Get exclusive discounts for your merchants.</li>
              <li>
                Dedicated support to help you deliver high ROI to your
                merchants.
              </li>
              <li>
                Build custom solutions for your merchants with our partner APIs.
              </li>
            </ul>
          </div>

          <div className={'button'}>
            <a href={siteMetadata.partnerTypeformUrl} target={'_blank'}>
              Sign Me Up
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PartnersPage
